.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.popup {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 800px; /* Adjust width as needed */
  text-align: center; /* Center the text inside the popup */
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 80vh; /* Limits height to 80% of the viewport */
  overflow-y: auto; /* Enables vertical scrolling */
}
.popup input {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
}

.popup .button-group {
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Adds space between the buttons */
}

.popup button {
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
  border: none;
  border-radius: 2px;
}

.popup button:first-child {
  background: #528528;
  color: white;
  width: 55%; /* Increase width for the "Calculate" button */
}

.popup button:last-child {
  background: #e1fdc9;
  color: rgb(70, 66, 66);
  width: 40%; /* Adjust width for the "Close" button */
}


.input-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Space between input and select */
  width: 100%;
}

.input-container input {
  width: 150px;
  padding: 8px;
  text-align: center;
}

.input-container select {
  width: 100px;
  padding: 8px;
  cursor: pointer;
  text-align: center;
}

button {
  margin-top: 10px;
  padding: 10px 15px;
  margin-top: 10px;
  margin-bottom: 11px;
  border: none;
  background: #528528;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background: #0056b3;
}


.image-container {
  margin-top: 10px;
  text-align: center;
}

.option-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.quantity-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  margin-bottom: 10px;
}