/* .App {
    text-align: center;
    font-family: Arial, sans-serif;
    padding: 10px;
    padding-top: 40px;
    padding-bottom: 100px;
    background-color: #f9f9f9;
    min-height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .App-header {
    max-width: 1100px;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 100%;
  }
  
  h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom:60px;
  }
   */
  .calculator-table {
    width: 100%;
    border-collapse: collapse;
    margin: auto;
    margin-top: 20px;
    text-align: center;
  }
  
  .calculator-table th, .calculator-table td {
    padding: 12px;
    border: 1px solid #ddd;
  }
  
  .calculator-table th {
    background-color: #f2f2f2;
  }
  
  .calculator-table td {
    background-color: #fafafa;
  }
  
  .calculator-table tr:hover td {
    background-color: #f1f1f1;
  }
  
  .calculator-table th.unit-column,
  .calculator-table td.unit-column {
    width: 40px; /* Adjust width as needed */
    min-width: 30px; /* Prevent shrinking too much */
    text-align: center;
  }
  
  .saved-entries-table {
    width: 100%;
    border-collapse: collapse;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 10px;
  }
  
  .saved-entries-table th, .saved-entries-table td {
    border: 1px solid #000;
    padding: 12px;
    text-align: center;
    font-size: 16px;
  }
  
  .saved-entries-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .saved-entries-table th.saved-column,
  .saved-entries-table td.saved-column {
    width: 40px; /* Adjust width as needed */
    min-width: 30px; /* Prevent shrinking too much */
    text-align: center;
  }
  .saved-entries-table th.measurement-column,
  .saved-entries-table td.measurement-column {
    width: 253px; /* Adjust width as needed */
    min-width: 60px; /* Prevent shrinking too much */
    text-align: center;
  }
  
  /* General Button Styling - Silver Polished Look */
  .action-btn {
    background: linear-gradient(145deg, #d9d9d9, #f2f2f2); /* Soft silver gradient */
    border: 2px solid #b0b0b0; /* Subtle silver border */
    color: #555; /* Dark grey text for contrast */
    font-size: 28px; /* Bigger text for better visibility */
    font-weight: 300; /* Thin font weight */
    padding: 2px 15px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    font-family: Arial, sans-serif; /* Clean, modern font */
  }
  
  /* Hover Effect */
  .action-btn:hover {
    background: linear-gradient(145deg, #f2f2f2, #ffffff);
    border-color: #999;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  }
  
  /* Remove Button - Slight Red Tint */
  .remove-btn {
    border-color: #b22222;
    color: #b22222;
  }
  
  .remove-btn:hover {
    background: linear-gradient(145deg, #f2d9d9, #ffffff);
    border-color: #a00000;
    color: #a00000;
  }
  
  /* Center the buttons inside the table */
  th:first-child, td:first-child {
    text-align: center;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .action-btn {
      font-size: 24px; /* Adjust font size for smaller screens */
      width: 40px;
      height: 40px;
    }
  }
  
  @media (max-width: 480px) {
    .action-btn {
      font-size: 20px;
      width: 35px;
      height: 35px;
    }
  }
  
  /* Add hover and focus effect */
  .remarks-input:hover,
  .remarks-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  /* Center align the remarks column */
  th:nth-child(7), td:nth-child(7) {
    text-align: center;
  }
  
  /* Adjust table column width for better visibility */
  th, td {
    padding: 12px;
  }
  
  th:nth-child(7) {
    width: 200px; /* Adjust width for remarks column */
    max-width: 100%; /* Ensure the column doesn't overflow */
  }
  
  /* Make remarks input responsive on smaller screens */
  @media (max-width: 768px) {
    th:nth-child(7), td:nth-child(7) {
      width: 150px; /* Smaller width on smaller screens */
    }
  
    .remarks-input {
      font-size: 12px; /* Reduce font size on smaller screens */
    }
  }
  
  /* Further adjustments for very small screens */
  @media (max-width: 480px) {
    th:nth-child(7), td:nth-child(7) {
      width: auto; /* Make it auto on very small screens */
      max-width: 100%;
    }
  
    .remarks-input {
      font-size: 12px; /* Smaller font for better fit */
    }
  }
  
  /* now everything is okay, but imporant update needed i.e. the save button in 
  calucution table should changed to save to saved when save clicked and information 
  updated to above table, and if user again in the same row made a single change 
  attemtpt, it again changed from saved to save, and thus it give otion to save the 
  currect changes */
  
  /* Add bottom margin to the remove button to create gap */
  .saved-entries-table td:nth-child(7) {
    width: 50px; /* Set a fixed width for the remove button column */
    text-align: center; /* Center horizontally */
    vertical-align: middle; /* Center vertically */
    padding: 0; /* Remove padding */
    padding-bottom: 10px; /* Add some padding to create space below */
    padding-top: 10px;
  }
  
  /* Remove Button */
  .remove-entry-btn {
    background-color: lightgrey; /* Background color */
    color: black; /* Text color */
    border: 2px solid grey; /* Border color */
    padding: 10px; /* Size of the button */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 0; /* Remove text, keep only the icon */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow effect */
    transition: background-color 0.3s, transform 0.2s ease; /* Smooth transitions */
    margin-bottom: 10px; /* Add margin below the button for a gap */
  }
  
  /* Hover Effect */
  .remove-entry-btn:hover {
    background-color: grey; /* Change background color on hover */
    transform: scale(1.05); /* Slight scale effect on hover */
  }
  
  /* Active state on click */
  .remove-entry-btn:active {
    transform: scale(0.95); /* Slight shrink effect on click */
  }
  
  /* Icon styling inside the button */
  .remove-entry-btn i {
    font-size: 20px; /* Set icon size */
    color: darkgrey; /* Icon color */
  }
  
  
  
  .customer-table-container, .site-table-container {
    border: 1px solid #ccc;  /* Thin border around the table containers */
    margin-bottom: 30px;
    padding: 25px;
    width: 50%;  /* Set the width of these containers */
    margin-left: auto;
    margin-right: auto;
  }
  
  .customer-table, .site-table {
    width: 50%;
    border-collapse: collapse;
  }
  
  .customer-table th, .site-table th, .customer-table td, .site-table td {
    border: 1px solid #ddd; /* Thin border for table rows and columns */
    padding: 8px;
    text-align: left;
  }
  
  .customer-table th, .site-table th {
    background-color: #f4f4f4;
  }
  
  